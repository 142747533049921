import { montserratBold, montserratSemibold } from '@/assets/fonts/montserrat'
import MasonryPartner from '@/components/MasonryPartner/MasonryPartner'
import { partnerList } from '@/helpers/content/id/partnerlist'
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function PartnerList() {
  const { t } = useTranslation();

  return (
    <div className='py-10 md:py-0 md:pt-16 lg:pt-16 lg:px-8 flex flex-col relative w-full h-full justify-center items-center'>
      <div className='w-full justify-center md:justify-normal items-center flex-col flex pb-6 md:pb-3 lg:pb-6 relative'>
        <div className={`${montserratSemibold.className} text-blue-primary-500 `}>{partnerList.tiny_title}</div>
      </div>
      <div className={`${montserratBold.className} text-gray-cool-700 leading-[44px] text-[36px] w-[7em] md:w-full justify-center leading-10 items-center text-center absolute top-[70px] md:top-[95px] lg:top-[3em]`}>{t('home_featured_in')}</div>
      <div className='flex py-7 md:py-3 justify-center w-full h-full'>
        <MasonryPartner />
      </div>
    </div>
  )
}
