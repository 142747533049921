import { montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import React from 'react'

const MiniCard = ({main_title, second_title, third_title, second_description, third_description}) => {
  return (
    <div className='rounded-[8px] flex bg-base-white border border-gray-cool-200 py-2 px-[10px] md:px-4 md:py-3 lg:px-4 lg:py-3'>
        <div className='flex w-full flex-col'>
            <h1 className={`${montserratSemibold.className} text-gray-cool-700 text-xs lg:text-[18px] md:text-[18px]`}>
                {main_title}
            </h1>
            <div className='flex pt-2 w-full justify-between items-center'>
                <div className='flex flex-col'>
                    <h3 className={`text-gray-cool-500 text-[8px] lg:text-sm md:text-sm ${montserratNormal.className}`}>{second_title}</h3>
                    <h5 className={`text-gray-cool-700 text-xs md:text-base lg:text-base ${montserratSemibold.className}`}>{second_description}</h5>
                </div>
                <div className='flex pl-2 flex-col'>
                    <h3 className={`text-gray-cool-500 text-[8px] lg:text-sm md:text-sm ${montserratNormal.className}`}>{third_title}</h3>
                    <h5 className={`text-gray-cool-700 text-xs md:text-base lg:text-base ${montserratSemibold.className}`}>{third_description}</h5>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MiniCard