import { montserratBold, montserratMedium, montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import Tabs from '@/components/Tabs/Tabs'
import { servicesContent, servicesTabs } from '@/helpers/tabs/services'
import React, { useEffect, useRef, useState } from 'react'
import checklist from "@/assets/testimonial/checklist.svg"
import Image from 'next/image'
import bookeeping from "@/assets/services/bookeeping.png"
import bookeepingEn from "@/assets/services/bookeeping_en.png"
import tax from "@/assets/services/tax.png"
import taxEn from "@/assets/services/tax_en.png"
import recon from "@/assets/services/recon.png"
import reconEn from "@/assets/services/recon_en.png"
import operational from "@/assets/services/operational.png"
import operatiosEn from "@/assets/services/operations_en.png"
import legalitation from "@/assets/services/legalitation.png"
import legalEn from "@/assets/services/legal_en.png"
import arrowRight from "@/assets/services/arrow-right.svg"
import { useTranslation } from 'react-i18next';
import Link from 'next/link'

export default function Services({ nextTab, moveToSection, servicesElementScroll, nextTabMobile, servicesMainMobileRef, servicesRef, setnextTab, lang }) {
    const servicesMobileRef = useRef([])
    const { t } = useTranslation();
    const [servicesList, setServicesList] = useState(servicesTabs)
    const [imageList, setimageList] = useState([bookeeping, tax, recon, operational, legalitation])
    const [language, setLanguage] = useState()
    const [prosesInterval, setprosesInterval] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            // Increment progress by 20% every second until it reaches 100%
            setprosesInterval(prev => prev + 100)
        }, 1000);

        // Clear the interval after 5 seconds

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setLanguage(lang)
        if (lang == 'id') {
            setimageList([bookeeping, tax, recon, operational, legalitation])
        } else {
            setimageList([bookeepingEn, taxEn, reconEn, operatiosEn, legalEn])
        }
    }, [lang])

    useEffect(() => {
        console.log("proses interval", prosesInterval)
        if (prosesInterval >= 500) {
            setnextTab(prev => prev >= (servicesList.length - 1) ? 0 : prev + 1);
            setprosesInterval(0)
        }
    }, [prosesInterval])


    useEffect(() => {
        console.log(servicesMobileRef.current[nextTabMobile])
        if (servicesElementScroll > 0) {
            servicesMobileRef.current[nextTabMobile].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start"
            })
        }
    }, [nextTabMobile])


    const handleChangeTab = (index) => {
        setprosesInterval(0)
        setnextTab(index)
    }

    return (
        <div className='w-full px-4 py-16 lg:py-16 lg:pb-0 lg:px-8 flex h-full flex-col'>
            <div className='w-full lg:container lg:mx-auto justify-center h-fit items-center flex-col'>
                <div className='flex justify-center items-center w-full flex-col'>
                    <h1 className={`${montserratBold.className} leading-[43.2px] text-gray-cool-700 text-[36px] text-center`}>{t(servicesContent.title)}</h1>
                    <p className={`w-full md:w-[568px] lg:w-1/3 xl:w-full xl:justify-center xl:flex ${montserratNormal.className} text-gray-cool-500 pt-3 pb-8 flex text-center`}>{t(servicesContent.description)}</p>
                </div>
            </div>
            <div ref={servicesRef} className='hidden lg:container max-w-[1280px] lg:mx-auto lg:flex flex-col w-full h-full justify-center items-center relative lg:py-6 px-8'>
                <Tabs>
                    <Tabs.Group className={"hidden w-full lg:flex h-full"}>
                        <div className='flex w-full justify-center items-center space-x-8'>
                            {
                                servicesList.map((val, index) => (
                                    <Tabs.Tab onClick={() => handleChangeTab(index)} className={"flex justify-center hover:cursor-pointer items-center flex-col w-full relative"}>
                                        <p className={`flex ${nextTab == index ? `text-blue-primary-500 ${montserratSemibold.className}` : `text-gray-cool-500 ${montserratMedium.className}`}`}>{t(val.name)}</p>
                                        <div className={`flex ${nextTab == index ? "bg-blue-primary-500 w-[218px] h-[2px]" : ""} mt-4 rounded-sm`} />
                                    </Tabs.Tab>
                                ))
                            }
                        </div>
                        <Tabs.Content className={"flex flex-col w-full h-full"}>
                            <div className='flex relative py-16 justify-between w-full h-full'>
                                <div className='flex h-full justify-between flex-col w-1/2'>
                                    <h1 className={`text-gray-cool-700 text-[30px] leading-9 ${montserratSemibold.className}`}>
                                        {t(servicesList[nextTab].title)}
                                    </h1>
                                    <p className={`${montserratNormal.className} py-8 text-gray-cool-500 text-[20px]`}>
                                        {t(servicesList[nextTab].description)}
                                    </p>
                                    <ul className='flex space-y-4 pb-8 justify-between flex-col'>
                                        {
                                            servicesList[nextTab].list_detail.map((service, i) => {
                                                if (t(service.lang) != "") {
                                                    return (
                                                        <li className='w-fit h-fit flex'>
                                                            <div className='w-auto h-auto justify-center flex items-center pr-[12px]'>
                                                                <div className='flex rounded-full justify-center items-center bg-gray-cool-100 p-[6px]'>
                                                                    <Image src={checklist} />
                                                                </div>
                                                            </div>
                                                            <div className={`${montserratNormal.className} text-gray-cool-500`}>{t(service.lang)}</div>
                                                        </li>
                                                    )
                                                }
                                            })
                                        }
                                    </ul>
                                    <Link locale={lang} href={servicesList[nextTab].url} className={`${montserratSemibold.className} hover:cursor-pointer text-blue-primary-500 space-x-3 pb-8 flex items-center`}>
                                        <p>{t("get_more_information")}</p>
                                        <div className='w-5'>
                                            <Image src={arrowRight} />
                                        </div>
                                    </Link>
                                </div>
                                <div className='flex w-1/2 pl-10 justify-center items-center h-full'>
                                    <Image src={imageList[nextTab]} />
                                </div>
                            </div>
                        </Tabs.Content>
                    </Tabs.Group>
                </Tabs>
            </div>
            <div ref={servicesMainMobileRef} className='lg:hidden overflow-x-scroll overflow-y-hidden flex flex-col h-full relative '>
                <div className='h-full lg:hidden'>
                    <div className='space-x-4 overflow-x-scroll overflow-y-hidden w-[110rem] px-4 flex h-full'>
                        {
                            servicesList.map((val, index) => (
                                <div ref={(el) => servicesMobileRef.current.push(el)} className='p-4 flex flex-col w-[400px] h-[750px] rounded-lg border border-gray-cool-300 bg-gray-cool-50'>
                                    <p className={`${montserratSemibold.className} text-blue-primary-500`}>{t(val.name)}</p>
                                    <p className={`${montserratSemibold.className} text-[20px] py-[6px] text-gray-cool-700`}>{t(val.title)}</p>
                                    <p className={`${montserratMedium.className} text-gray-cool-500`}>{t(val.description)}</p>
                                    <div className='flex py-4 justify-center items-center'>
                                        <Image src={imageList[index]} />
                                    </div>
                                    <ul className='flex space-y-4 pb-4 justify-between flex-col'>
                                        {
                                            val.list_detail.map((service, i) => {
                                                if (t(service.lang) != "") {
                                                    return (
                                                        <li className='w-fit h-fit flex'>
                                                            <div className='relative flex pr-[12px]'>
                                                                <div className='flex rounded-full left-0 w-5 h-5 justify-center items-center bg-gray-cool-100'>
                                                                    <Image src={checklist} />
                                                                </div>
                                                            </div>
                                                            <div className={`${montserratNormal.className} text-gray-cool-500 text-sm`}>{t(service.lang)}</div>
                                                        </li>
                                                    )
                                                }
                                            })
                                        }
                                    </ul>
                                    <Link locale={lang} href={val.url} className={`${montserratSemibold.className} hover:cursor-pointer text-blue-primary-500 space-x-3 pb-8 flex items-center`}>
                                        <p>{t("get_more_information")}</p>
                                        <div className='w-5'>
                                            <Image src={arrowRight} />
                                        </div>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
