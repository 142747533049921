import ChartWrapper from '@/components/ChartWrapper/ChartWrapper'
import React from 'react'
import ctaImage1 from '@/assets/ctasection/ctaimage1.png'
import Image from 'next/image'
import logoWhite from "@/assets/ctasection/lo-white.svg"
import { montserratBold, montserratMedium, montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import { ctaSection } from '@/helpers/content/id/ctasection'
import Badges from '@/components/Badges/Badges'
import { ArrowUpIcon } from '@heroicons/react/24/outline'
import uptrendCta from "@/assets/ctasection/uptrendCta.svg"
import Button from '@/components/Button/Button'
import wa from '@/assets/ctasection/waicon.svg'
import Link from 'next/link'
import { useTranslation } from 'react-i18next';

export default function CTASection() {
    const { t } = useTranslation();
    return (
        <div className='flex w-full lg:px-0 lg:space-x-5 lg:pt-16 px-4 py-2 lg:flex-row lg:justify-between lg:items-center flex-col relative h-full lg:mx-auto  max-w-[1280px] lg:container'>
            <div className='flex -left-60 md:left-0 lg:-left-20 lg:w-full lg:order-none order-2 justify-center items-center relative w-full h-[80vh] md:h-full md:w-[100%]'>
                <div className='flex relative pt-6 lg:-left-12 w-fit'>
                    <div className='rounded-lg relative flex-col overflow-hidden w-[600px] h-[464px] border border-gray-cool-200'>
                        <div className='flex bg-gray-cool-200 justify-center pb-4 w-full items-center flex-col'>
                            <div className='flex justify-center py-4 w-full items-center flex-col'>
                                <p className={`${montserratSemibold.className} text-[20px] text-gray-cool-700`}>Monthly Meet 1:1</p>
                                <p className={`${montserratNormal.className} text-sm text-gray-cool-500`}>You, Ledgerowl</p>
                            </div>
                        </div>
                        <div className='w-full h-full'>
                            <ChartWrapper />
                        </div>
                    </div>
                    <div className='rounded-lg border drop-shadow-lg border-gray-cool-100 bg-blue-primary-50 top-20 -right-20 z-20 absolute w-[242px] h-[163px]'>
                        <div className='h-full flex mx-auto justify-center items-center absolute w-full'>
                            <Image src={ctaImage1} className='object-cover w-[80%] absolute bottom-0' />
                        </div>
                        <div className='overflow-hidden h-full flex mx-auto justify-center items-center absolute w-full'>
                            <div className='flex absolute bottom-0 h-[50px] mix-blend-multiply w-full bg-gray-cool-500 opacity-40 blur-sm'></div>
                            <div className='flex absolute bottom-0 h-[25px] mix-blend-multiply w-full bg-gray-cool-500 opacity-45 blur-md'></div>
                        </div>
                        <div className='overflow-hidden p-4 flex justify-between bottom-0 absolute w-full'>
                            <Image src={logoWhite} />
                            <p className={`${montserratSemibold.className} text-base-white text-[18px]`}>Ledgerowl</p>
                        </div>
                    </div>
                    <div className='rounded-lg border border-gray-cool-200 p-[10px] bg-base-white bottom-20 -right-20 z-20 flex flex-col absolute w-[242px] h-fit'>
                        <p className={`${montserratNormal.className} text-gray-cool-500`}><strong className={`${montserratNormal.className} text-gray-cool-700 pr-1`}>{ctaSection.mini_card.title_bold}</strong>{t(ctaSection.mini_card.title_after)}</p>
                        <div className={`pt-2 bg-semantic-positive-100 px-[12px] py-1 text-semantic-positive-600 rounded-[6px] bg-primary-blue flex justify w-fit items-center`}>
                            <div className='w-[20px] font-bold'>
                                <ArrowUpIcon />
                            </div>
                            <div className={`pl-2 text-[22px] ${montserratSemibold.className}`}>
                                86,9%
                            </div>
                        </div>
                        <div className='flex pt-[10px] w-full flex-row items-center'>
                            <div className='flex items-center w-[12px]'>
                                <ArrowUpIcon />
                            </div>
                            <p className={`text-[12px] flex items-center pl-[3.742px] ${montserratSemibold.className}`}>60,7%</p>
                            <p className={`pl-[7.45px] ${montserratNormal.className} text-[12px] flex text-gray-cool-500`}>
                                {t(ctaSection.mini_card.desc)}
                            </p>
                        </div>
                        <div className='absolute -z-10 bottom-0 right-0'>
                            <Image src={uptrendCta} className='w-[130px]' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center lg:w-full flex-col'>
                <h1 className={`${montserratBold.className} leading-[44px] text-[36px] lg:w-full md:w-[14em] lg:justify-start justify-center items-center lg:text-left text-center leading-[43px] text-gray-cool-700 flex`}>{t(ctaSection.main.title)}</h1>
                <p className={`pt-3 pb-8 ${montserratNormal.className} md:w-[520px] flex items-center text-center lg:w-full text-gray-cool-500 lg:text-left text-[20px]`}>
                    {t(ctaSection.main.description)}
                </p>
                <Link className='w-full pb-16 flex lg:text-left text-center justify-center lg:justify-start' target='__blank' href={`https://api.whatsapp.com/send?phone=6281287889023&text=${t('floating_message_home')}`}>
                    <Button className={`${montserratMedium.className} w-fit hover:cursor-pointer flex justify-center items-center  text-base-white hover:bg-blue-primary-600 bg-blue-primary-500`}>
                        {t(ctaSection.main.button.text)}
                        <span className='pl-3'><Image src={wa} /></span>
                    </Button>
                </Link>
            </div>
        </div>
    )
}
