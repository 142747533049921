export const testimoniContent = {
    mini_title : "testimoni_content_mini_title",
    title: "testimoni_content_title",
    description: "testimoni_content_description"
}

export const testimoniList = [
    {
        active: true,
        pic_name: "Khaled",
        pic_position: "Business Owner",
        name_client: "Tennis Launcher",
        type_business: "Education & Training",
        title_commentary: "testimoni_comentary_tenis_launcer",
        commentary : "",
        commentary_2: "",
        type_services : [
            {
                type:"bookeeping",
                name: "service_bookeeping"
            }
        ]
    },
    {
        active: false,
        pic_name: "Abirama",
        pic_position: "CEO",
        name_client: "Petiq Bistro",
        type_business: "FnB Business",
        title_commentary: "",
        commentary : "testimoni_comentary_petiq",
        commentary_2: "",
        type_services : [
            {
                type:"bookeeping",
                name: "service_bookeeping"
            },
            {
                type:"tax",
                name: "tax"
            }
        ]
    },
    {
        active: false,
        pic_name: "Henrietta Danubrata & Cindy Suryadinata",
        pic_position: "Director",
        name_client: "The Blanc Space",
        type_business: "Design Studio",
        title_commentary: "testimoni_title_comentary_blank_space",
        commentary : "testimoni_comentary_blank_space",
        commentary_2: "testimoni_comentary_2_blank_space",
        type_services : [
            {
                type:"bookeeping",
                name: "service_bookeeping"
            },
            {
                type:"tax",
                name: "tax"
            }
        ]
    },
    {
        active: false,
        pic_name: "Kent Tjai",
        pic_position: "Business Owner",
        name_client: "Menya Kenji",
        type_business: "FnB Business",
        title_commentary: "",
        commentary : "testimoni_comentary_menya_kenji",
        commentary_2: "testimoni_comentary_2_menya_kenji",
        type_services : [
            {
                type:"bookeeping",
                name: "service_bookeeping"
            },
        ]
    },
    {
        active: false,
        pic_name: "Rishanda Singgih",
        pic_position: "Business Owner",
        name_client: "The Rising",
        type_business: "Music Producer",
        title_commentary: "testimoni_title_comentary_the_rissing",
        commentary : "",
        commentary_2: "",
        type_services : [
            {
                type:"bookeeping",
                name: "service_bookeeping"
            },
            {
                type:"tax",
                name: "tax"
            },
            {
                type:"legalitation",
                name: "business_legality"
            }
        ]
    },
]