import React from 'react'
import Badges from '../Badges/Badges'
import { ArrowUpIcon } from '@heroicons/react/24/outline'
import { montserratMedium, montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import uptrend from "@/assets/homesection/uptrend-2.svg"
import Image from 'next/image'
function MiniCardStackThree({ title, description, detail_description, badgeText }) {
  return (
    <div className={`flex relative`}>
        <div className='flex left-2 border border-gray-cool-200 rounded-[8px] w-[120px] md:w-[204px] lg:w-[204px] px-4 py-3 absolute z-10 -top-4 bg-base-white' />
        <div className='flex left-1 border border-gray-cool-200 rounded-[8px] w-[130px] md:w-[214px] lg:w-[214px] px-4 py-3 absolute z-10 -top-2 bg-base-white' />
        <div className='flex overflow-hidden flex-col space-y-[2px] border border-gray-cool-200 py-2 px-[10px] rounded-[8px] md:w-[224px] lg:w-[224px] md:px-4 md:py-3 lg:px-4 lg:py-3 relative z-20 bg-base-white'>
            <h1 className={`${montserratNormal.className} z-20 text-[8px] md:text-sm lg:text-sm`}>{title}</h1>
            <h2 className={`${montserratSemibold.className} z-20 text-xs md:text-[18px] lg:text-[18px]`}>{description}</h2>
            <div className='flex z-20 items-center pt-[6px]'>
                <Badges className={"px-[1.28px] md:px-2 lg:px-2 text-semantic-positive-600 bg-semantic-positive-100"}>
                    <div className='w-[8px] h-[8px] md:w-3 md:h-3 lg:w-3 lg:h-3'>
                        <ArrowUpIcon />
                    </div>
                    <div className={`${montserratSemibold.className} text-[8px] md:text-xs lg:text-xs px-[2px]`}>
                        {badgeText}
                    </div>
                </Badges>
                <h5 className={`${montserratNormal.className} px-1 md:text-sm lg:text-sm text-[8px] text-gray-cool-500`}>{detail_description}</h5>
            </div>
            {/* <div className='flex bg-pevitaBlue absolute right-10 bg-blend-overlay h-[20vh] z-40 w-[80%] '></div> */}
            <div className='absolute z-0 right-0'>
                <Image src={uptrend} />
            </div>
        </div>
    </div>
  )
}

export default MiniCardStackThree