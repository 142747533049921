import MiniCard from '@/components/MiniCard/MiniCard'
import MiniCardStackThree from '@/components/MiniCardStackThree/MiniCardStackThree'
import React from 'react'

export default function MiniCardParent({isThreeCard,cardStackThree,miniCard}) {
  return (
    <>
    <div className={`z-20 drop-shadow-xl absolute md:flex md:justify-center ${!isThreeCard ? "top-5 left-5 lg:top-20 md:top-8 md:w-[60rem] md:-left-[22rem] lg:-left-24" : "top-16 left-5 lg:top-20 md:top-32 md:w-full md:left-0 lg:-left-16"} lg:-left-16 flex lg:w-auto items-center`}>
        {
          isThreeCard ? (
            <MiniCardStackThree 
              title={cardStackThree.title} 
              description={cardStackThree.description} 
              detail_description={cardStackThree.detail_description}
              badgeText={cardStackThree.badge_text}
            />
          ) : (
            <MiniCard
              main_title={cardStackThree.main_title}
              second_title={cardStackThree.second_title}
              third_title={cardStackThree.third_title}
              second_description={cardStackThree.second_description}
              third_description={cardStackThree.third_description}
            />
          )
        }
    </div>
    <div className={`z-20 left-5 ${!isThreeCard ? "bottom-10 md:-left-20 md:bottom-20 lg:-left-20" : "md:bottom-20 lg:bottom-20 lg:w-auto md:left-[50%] lg:left-20"} drop-shadow-xl absolute flex`}>
        <MiniCard
            main_title={miniCard.main_title}
            second_title={miniCard.second_title}
            third_title={miniCard.third_title}
            second_description={miniCard.second_description}
            third_description={miniCard.third_description}
        />
    </div>
    </>
  )
}
