export const listColorBadges = [
    {
        name: "success",
        bgColor: "bg-semantic-positive-100",
        textColor: "text-semantic-positive-600"
    },
    {
        name: "warning",
        bgColor: "bg-semantic-notice-100",
        textColor: "text-semantic-notice-600"
    },
    {
        name: "failed",
        bgColor: "bg-semantic-negative-100",
        textColor: "text-semantic-negative-600"
    },
    {
        name: "primary",
        bgColor: "bg-blue-primary-100",
        textColor: "text-blue-primary-600"
    },
    {
        name: "secondary",
        bgColor: "bg-blue-secondary-100",
        textColor: "text-blue-secondary-600"
    },
]