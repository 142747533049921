export const ctaSection = {
    main: {
        title:"cta_main_title",
        description: "cta_main_description",
        button: {
            text:"cta_main_button_text",
            icon:'wa'
        }
    },
    mini_card: {
        title_bold:"Profit",
        title_after:"this_month",
        desc: "last_week"
    }
}