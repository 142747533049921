import React from 'react'
import Groups from './Groups'
import Tab from './Tab'
import Content from './Content'

const Tabs = ({className,children}) => {
  return (
    <div className={`flex relative w-full h-full flex-col`}>
        {/* <div className='flex space-x-4 w-full'> */}
            {children}
        {/* </div> */}
    </div>
  )
}

Tabs.Group = Groups
Tabs.Tab = Tab
Tabs.Content = Content

export default Tabs
