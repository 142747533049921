import React from 'react'
import partner1 from "@/assets/partnership/partner1.png";
import partner2 from "@/assets/partnership/partner2.png";
import partner3 from "@/assets/partnership/partner3.png";
import partner4 from "@/assets/partnership/partner4.png";
import partner5 from "@/assets/partnership/partner5.png";
import partner6 from "@/assets/partnership/partner6.png";
import partner7 from "@/assets/partnership/partner7.png";
import partner8 from "@/assets/partnership/partner8.png";
import partner9 from "@/assets/partnership/partner9.png";
import Image from 'next/image';


export default function MasonryPartner() {
  return (
    <div className='flex flex-col justify-center w-[328px] md:w-[600px] lg:w-[1216px]'>
        <div className='flex w-full -z-10 h-full overflow-hidden relative rounded-full flex-col'>
            <div className='w-full flex z-20 -top-5 justify-center items-center h-[50px] bg-base-white blur-sm absolute rounded-full'></div> 
            <div className='w-full flex z-20 -bottom-5 justify-center items-center h-[50px] bg-base-white blur-sm absolute rounded-full'></div> 
            <div className='w-[50px] flex z-20 -left-5 justify-center items-center h-full bg-base-white blur-sm absolute rounded-full'></div> 
            <div className='w-[50px] flex z-20 -right-5 justify-center items-center h-full bg-base-white blur-sm absolute rounded-full'></div> 
            <div className='flex relative z-10 justify-normal w-full'>
                <div className='flex border border-gray-cool-300 w-[100px] h-[60px] md:w-[106px] md:h-[50px] lg:w-[204px] lg:h-[76px]'/>
                <div className='flex border relative z-30 bg-gray-cool-100 border-gray-cool-300 w-[78px] h-[60px] md:w-[113px] md:h-[50px] lg:w-[112px] lg:h-[76px]'/>
                <div className='flex border border-gray-cool-300 w-[100px] h-[60px] md:w-[212px] md:h-[50px] lg:w-[76px] lg:h-[76px]'/>  
                <div className='flex border bg-gray-cool-100 border-gray-cool-300 w-[50px] h-[60px] md:w-[119px] md:h-[50px] lg:w-[163.25px] lg:h-[76px]'/> 
                <div className='hidden md:flex border border-gray-cool-300 md:w-[50px] md:h-[50px] lg:w-[95.02px] lg:h-[76px]'/>   
                <div className='hidden lg:flex border bg-gray-cool-100 border-gray-cool-300 w-[163.25px] h-[76px]'/>
                <div className='hidden lg:flex border border-gray-cool-300 w-[76px] h-[76px]'/>  
                <div className='hidden lg:flex border bg-gray-cool-100 border-gray-cool-300 w-[163.25px] h-[76px]'/>
                <div className='hidden lg:flex border border-gray-cool-300 w-[163.25px] h-[76px]'/>
            </div>
            <div className='flex w-full justify-normal flex-wrap'>
                <div className='flex order-4 md:order-6 bg-gray-cool-100 lg:order-none w-[157.85px] h-[60px] md:w-[149.85px] md:h-[76px] lg:w-[165.85px] lg:h-[76px] px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner1} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex order-9 lg:order-none w-[64px] h-[60px] md:w-[73px] md:h-[76px] lg:w-[81.47px] lg:h-[76px] px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner2} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex order-7 lg:order-none bg-gray-cool-100 w-[64px] h-[60px] md:w-[80.8px] md:h-[76px] lg:w-[96.8px] lg:h-[76px] px-2 lg:px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner3} className='object-contain bg-contain' />
                </div>
                <div className='flex order-3 md:order-3 lg:order-none w-[106px] h-[60px] md:w-[200px] md:h-[76px] lg:w-[181.28px] lg:h-[76px] px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner4} className='relative z-20 object-contain w-[70%] lg:w-1/2 bg-contain' />
                </div>
                <div className='flex order-2 md:order-2 bg-gray-cool-100 lg:order-none w-[164px] h-[60px] md:w-[200px] md:h-[76px] lg:w-[181.28px] lg:h-[76px] px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner5} className='relative z-20 object-contain  bg-contain' />
                </div>
                <div className='flex order-1 md:order-1 lg:order-none w-[164px] h-[60px] md:w-[200px] md:h-[76px] lg:w-[181.28px] lg:h-[76px] px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner6} className='relative z-20 object-contain  bg-contain' />
                </div>
                <div className='flex order-6 md:order-4 bg-gray-cool-100 lg:order-none w-[95.17px] h-[60px] md:w-[97.17px] md:h-[76px] lg:w-[113.17px] lg:h-[76px] lg:px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner7} className='object-contain  bg-contain' />
                </div>
                <div className='flex order-5 lg:order-none w-[62.44px] h-[60px] md:w-[70px] md:h-[76px] lg:w-[70.44px] lg:h-[76px] px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner8} className='relative z-20 object-contain  bg-contain' />
                </div>
                <div className='flex order-8 lg:order-none bg-gray-cool-100 w-[103.93px] h-[60px] md:w-[128.41px] md:h-[76px] lg:w-[144.41px] lg:h-[76px] px-4 justify-center items-center border border-gray-cool-300'>
                    <Image src={partner9} className='relative z-20 object-contain  bg-contain' />
                </div>
            </div>
            <div className='flex relative  z-10 justify-normal w-full'>
                <div className='flex border border-gray-cool-300 w-[50px] h-[60px] md:w-[106px] md:h-[50px] lg:w-[204px] lg:h-[76px]'/>
                <div className='flex border border-gray-cool-300 w-[100px] h-[60px] md:w-[113px] md:h-[50px] lg:w-[112px] lg:h-[76px]'/>
                <div className='flex border border-gray-cool-300 w-[78px] h-[60px] md:w-[212px] md:h-[50px] lg:w-[76px] lg:h-[76px]'/>  
                <div className='flex border border-gray-cool-300 w-[100px] h-[60px] md:w-[119px] md:h-[50px] lg:w-[163.25px] lg:h-[76px]'/> 
                <div className='hidden md:flex border border-gray-cool-300 md:w-[50px] md:h-[50px] lg:w-[95.02px] lg:h-[76px]'/>   
                <div className='hidden lg:flex border border-gray-cool-300 w-[163.25px] h-[76px]'/>
                <div className='hidden lg:flex border border-gray-cool-300 w-[76px] h-[76px]'/>  
                <div className='hidden lg:flex border border-gray-cool-300 w-[163.25px] h-[76px]'/>
                <div className='hidden lg:flex border border-gray-cool-300 w-[163.25px] h-[76px]'/>
            </div>
        </div>
    </div>
  )
}
