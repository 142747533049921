import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import client1 from "@/assets/clientsections/client1.png"
import client2 from "@/assets/clientsections/client2.png"
import client3 from "@/assets/clientsections/client3.png"
import client4 from "@/assets/clientsections/client4.png"
import client5 from "@/assets/clientsections/client5.png"
import client6 from "@/assets/clientsections/client6.png"
import client7 from "@/assets/clientsections/client7.png"
import client8 from "@/assets/clientsections/client8.png"
import client9 from "@/assets/clientsections/client9.png"
import client10 from "@/assets/clientsections/client10.png"
import client11 from "@/assets/clientsections/client11.png"
import client12 from "@/assets/clientsections/client12.png"
import client13 from "@/assets/clientsections/client13.png"
import client14 from "@/assets/clientsections/client14.png"
import client15 from "@/assets/clientsections/client15.png"
import client16 from "@/assets/clientsections/client16.png"
import client17 from "@/assets/clientsections/client17.png"
import client18 from "@/assets/clientsections/client18.png"
import client19 from "@/assets/clientsections/client19.png"
import client20 from "@/assets/clientsections/client20.png"
import client21 from "@/assets/clientsections/client21.png"
import client22 from "@/assets/clientsections/client22.png"
import client23 from "@/assets/clientsections/client23.png"
import client24 from "@/assets/clientsections/client24.png"
import client25 from "@/assets/clientsections/client25.png"
import client26 from "@/assets/clientsections/client26.png"
import client27 from "@/assets/clientsections/client27.png"
import client28 from "@/assets/clientsections/client28.png"
import client29 from "@/assets/clientsections/client29.png"
import client30 from "@/assets/clientsections/client30.png"

const Mosanry = () => {
  return ( 
    <>
        <div className='w-full flex z-20 -top-5 justify-center items-center h-[60px] md:h-[45px] lg:h-[50px] bg-base-white blur-sm absolute rounded-full'></div> 
        <div className='w-full flex z-20 -bottom-5 justify-center items-center h-[60px] md:h-[45px] lg:h-[50px] bg-base-white blur-sm absolute rounded-full'></div> 
        <div className='w-[60px] md:w-[255px] lg:w-[140px] flex z-20 -left-5 justify-center items-center h-full bg-base-white blur-sm absolute rounded-full'></div> 
        <div className='w-[60px] md:w-[255px] lg:w-[140px] flex z-20 -right-5 justify-center items-center h-full bg-base-white blur-sm absolute rounded-full'></div> 
    {/* <div className='flex w-full -z-10 h-full overflow-hidden border-2 relative rounded-full flex-col'> */}
        <div className='flex w-[328px] md:w-[600px] lg:w-full flex-wrap md:justify-center md:items-center'>
            <div className='flex w-[114px] h-[50px] md:w-[250px] md:h-[50px] lg:w-[76px] lg:h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='flex w-[50px] h-[50px] md:w-[50px] md:h-[50px] lg:w-[188.73px] lg:h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='flex w-[114px] h-[50px] md:w-[250px] md:h-[50px] lg:w-[76px] lg:h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='flex w-[50px] h-[50px] md:w-[50px] md:h-[50px] lg:w-[117.82px] lg:h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[188.73px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[188.73px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
        </div>
        <div className='lg:hidden flex flex-col md:justify-center md:items-center flex-wrap w-[328px] md:w-[600px] lg:w-full h-full'>
            <div className='flex flex-wrap md:justify-center md:items-center w-full'>
                <div className='flex w-[114px] h-[50px] px-4 justify-center items-center  border-[0.5px] border-gray-cool-300'>
                    <Image src={client3} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[50px] px-3 bg-gray-cool-100 h-[50px] justify-center items-center border-[0.5px] border-gray-cool-300'>
                    <Image src={client4} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[114px] h-[50px] border-[0.5px] px-4 justify-center items-center border-gray-cool-300'>
                    <Image src={client5} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[50px] px-3 h-[50px] justify-center items-center bg-gray-cool-100 border-[0.5px] border-gray-cool-300'>
                    <Image src={client22} className='relative z-20 w-full object-contain bg-contain' />
                </div>
                <div className='flex w-[50px] h-[50px] px-3 justify-center items-center border-[0.5px] border-gray-cool-300'>
                    <Image src={client12} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[142px] px-3 h-[50px] justify-center items-center border-[0.5px] border-gray-cool-300'>
                    <div className='flex w-[70%] rounded px-2 py-1 justify-center items-center bg-base-black h-fit'>
                        <Image src={client15} className='relative z-20 object-contain bg-contain' />
                    </div>
                </div>
                <div className='flex w-[50px] bg-gray-cool-100 justify-center items-center h-[50px] border-[0.5px] px-3 border-gray-cool-300'>
                    <Image src={client16} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[86px] bg-base-white justify-center items-center md:bg-gray-cool-100 px-3 h-[50px] border-[0.5px] border-gray-cool-300'>
                    <Image src={client6} className='relative z-20 w-full object-contain bg-contain' />
                </div>
                <div className='flex w-[128px] bg-gray-cool-100 md:bg-base-white h-[50px] px-3 justify-center items-center  border-[0.5px] border-gray-cool-300'>
                    <Image src={client9} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[50px] px-3 bg-base-white md:bg-gray-cool-100 h-[50px] justify-center items-center border-[0.5px] border-gray-cool-300'>
                    <Image src={client29} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[100px] h-[50px] border-[0.5px] px-3 justify-center border-gray-cool-300'>
                    <Image src={client25} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[50px] bg-gray-cool-100 px-3 h-[50px] border-[0.5px] border-gray-cool-300'>
                    <Image src={client30} className='relative z-20 w-full object-contain bg-contain' />
                </div>
                <div className='flex w-[50px] h-[50px] px-3 justify-center items-center  border-[0.5px] border-gray-cool-300'>
                    <Image src={client20} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[113px] bg-base-white md:bg-gray-cool-100 px-3 h-[50px] justify-center items-center border-[0.5px] border-gray-cool-300'>
                    <Image src={client1} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[50px] bg-gray-cool-100 md:bg-base-white h-[50px] border-[0.5px] px-3 justify-center border-gray-cool-300'>
                    <Image src={client8} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[115px] md:bg-gray-cool-100 bg-base-white px-3 h-[50px] border-[0.5px] border-gray-cool-300'>
                    <Image src={client11} className='relative z-20 w-full object-contain bg-contain' />
                </div>
                <div className='flex w-[90px] bg-gray-cool-100 md:bg-base-white h-[50px] px-3 justify-center items-center  border-[0.5px] border-gray-cool-300'>
                    <Image src={client28} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[97px] px-3 h-[50px] md:bg-gray-cool-100 bg-base-white justify-center items-center border-[0.5px] border-gray-cool-300'>
                    <Image src={client17} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[50px] h-[50px] border-[0.5px] px-3 justify-center border-gray-cool-300'>
                    <Image src={client14} className='relative z-20 object-contain bg-contain' />
                </div>
                <div className='flex w-[91px] bg-gray-cool-100 px-3 h-[50px] border-[0.5px] border-gray-cool-300'>
                    <Image src={client23} className='relative z-20 w-full object-contain bg-contain' />
                </div>
            </div>
        </div>
        <div className='lg:flex hidden w-[1216px]'>
            <div className='flex flex-wrap w-full'>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[178.6px] h-[76px]'>
                    <Image src={client1} className='relative z-20 object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client2} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[178.6px] h-[76px]'>
                    <Image src={client3} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client4} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[178.6px] h-[76px]'>
                    <Image src={client5} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[95.02px] h-[76px]'>
                    <Image src={client6} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[178.6px] h-[76px]'>
                    <Image src={client7} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client8} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[178.6px] h-[76px]'>
                    <Image src={client9} className='relative z-20 object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client10} className='relative z-20 object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[215.16px] h-[76px]'>
                    <Image src={client11} className='relative z-20 object-contain bg-contain w-[70%]'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client12} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[215.16px] h-[76px]'>
                    <Image src={client13} className='object-contain w-[70%] bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client14} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[215.16px] h-[76px]'>
                    <div className='flex w-[70%] rounded px-2 py-1 justify-center items-center bg-base-black h-fit'>
                        <Image src={client15} className='object-contain bg-contain'/>
                    </div>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client16} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[114.51px] h-[76px]'>
                    <Image src={client17} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client18} className='relative z-20 object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client19} className='relative z-20 object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client20} className='relative z-20 object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[171px] h-[76px]'>
                    <Image src={client21} className='relative z-20 object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client22} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[171px] h-[76px]'>
                    <Image src={client23} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[76px] h-[76px]'>
                    <div className='flex rounded px-2 py-1 justify-center items-center bg-base-black h-fit'>
                        <Image src={client24} className='object-contain bg-contain'/>
                    </div>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[171px] h-[76px]'>
                    <Image src={client25} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client26} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client27} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 bg-gray-cool-100 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client29} className='object-contain bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[171px] h-[76px]'>
                    <Image src={client28} className='relative z-20 object-contain w-[70%] bg-contain'/>
                </div>
                <div className='border-[0.5px] px-4 border-gray-cool-300 flex justify-center items-center w-[76px] h-[76px]'>
                    <Image src={client30} className='relative z-20 object-contain bg-contain'/>
                </div>
            </div>
        </div>
        <div className='flex w-[328px] md:w-[600px] lg:w-full flex-wrap md:justify-center md:items-center'>
            <div className='flex w-[114px] h-[50px] md:w-[250px] md:h-[50px] lg:w-[76px] lg:h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='flex w-[50px] h-[50px] md:w-[50px] md:h-[50px] lg:w-[188.73px] lg:h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='flex w-[114px] h-[50px] md:w-[250px] md:h-[50px] lg:w-[76px] lg:h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='flex w-[50px] h-[50px] md:w-[50px] md:h-[50px] lg:w-[117.82px] lg:h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[188.73px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[188.73px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
            <div className='hidden lg:flex w-[76px] h-[76px] p-4 border-[0.5px] border-gray-cool-300' />
        </div>
    {/* </div> */}
    </>
  )
}

export default Mosanry