import { montserratBold, montserratMedium, montserratNormal, montserratSemibold } from '@/assets/fonts/montserrat'
import { pricingSection } from '@/helpers/content/id/pricing'
import cashIcon from "@/assets/pricingsection/cash.svg"
import boltIcon from "@/assets/pricingsection/bolt.svg"
import clockIcon from "@/assets/pricingsection/clock.svg"
import checklistIcon from "@/assets/pricingsection/checklist.svg"

import Image from 'next/image'
import React from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'
import Button from '@/components/Button/Button'
import { useTranslation } from 'react-i18next';

function PricingSection({ pricingRef, moveToSection }) {
    const { t } = useTranslation();
    let pricingContent = pricingSection

    return (
        <div ref={pricingRef} className='flex relative w-full justify-center items-center flex-col h-full'>
            <div className='flex px-4 py-16 md:py-16 lg:px-8 text-base-white bg-blue-primary-900 w-full'>
                <div className='flex lg:mx-auto lg:container max-w-[1280px] flex-col w-full justify-center items-center text-center'>
                    <h5 className={`${montserratSemibold.className} text-blue-primary-400`}>{t(pricingContent.title)}</h5>
                    <h2 className={`${montserratSemibold.className} leading-[44px] text-[36px] text-gray-cool-25 md:py-0 py-3`}>{t(pricingContent.main_title)}</h2>
                    <h4 className={`${montserratNormal.className} text-gray-cool-200 md:w-[70vh] lg:w-1/3 md:py-0 py-3 md:pb-16`}>{t(pricingContent.description)}</h4>
                    <ul className='md:py-16 lg:py-0 py-0 grid lg:grid-cols-3 gap-y-12 lg:gap-x-12 grid-cols-1'>
                        {
                            pricingContent.list.map((val, index) => (
                                <li className='inline-flex flex-col md:flex-row space-x-4 text-left' key={index}>
                                    <div className='block md:px-0 px-4'>
                                        <div className='flex w-[30px] h-[30px] md:w-[30px] justify-center md:h-[30px] bg-blue-primary-100 rounded-full items-center'>
                                            <Image width={20} height={20} alt={val.image} src={val.image == 'cash' ? cashIcon : val.image == "bolt" ? boltIcon : clockIcon} />
                                        </div>
                                    </div>
                                    <div className='block md:py-0 py-4'>
                                        <p className={`${montserratSemibold.className} text-gray-cool-25 text-[20px]`}>{t(val.title)}</p>
                                        <p className={`${montserratNormal.className} text-base text-gray-cool-300`}>{t(val.description)}</p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className='w-full relative h-full flex justify-center items-center'>
                <div className='flex w-full h-[640px] lg:h-full md:h-fit flex-col'>
                    <div className='bg-blue-primary-900 w-full h-full py-20 md:py-18 lg:py-16'>

                    </div>
                    <div className='bg-base-white w-full h-full py-20 md:py-44 lg:py-24'>

                    </div>
                </div>
                <div className=' absolute px-4 lg:px-8 flex md:w-[736px] lg:w-full w-full h-full justify-center items-center  max-w-[1280px]'>
                    <div className='xl:container h-[680px] md:h-[630px] lg:h-full lg:justify-between inset-0 w-full md:w-[640px] lg:w-full flex flex-col lg:flex-row bg-base-white drop-shadow-lg relative rounded-lg'>
                        <div className='md:p-8 z-20 relative lg:h-fit md:h-fit p-6 flex flex-col w-full lg:w-full'>
                            <div className='flex flex-col md:pb-[0px] pb-[0px] lg:pb-[0px]'>
                                <h4 className={`${montserratBold.className} text-gray-cool-700 text-[20px]`}>{t(pricingContent.detail.title)}</h4>
                                <h5 className={`${montserratNormal.className} text-gray-cool-500 text-[20px]`}>{t(pricingContent.detail.description)}</h5>
                            </div>
                            <div className='flex pt-[20px] md:pt-10 flex-col'>
                                <div className='flex items-center'>
                                    <p className={`${montserratSemibold.className} text-blue-primary-500 text-[20px] pr-4`}>{t(pricingContent.detail.list_title)}</p>
                                    <hr className='border border-gray-cool-200 w-full' />
                                </div>
                                <ul className='lg:py-4 lg:pt-4 md:pt-4 pt-4 gap-4 grid md:grid-cols-2 grid-cols-1'>
                                    {
                                        pricingContent.detail.list.map((val, index) => (
                                            <li key={index} className='flex items-center border-secondr space-x-2'>
                                                <div className='text-base-white rounded-full flex justify-center items-center p-1'>
                                                    {/* <CheckIcon/> */}
                                                    <Image src={checklistIcon} />
                                                </div>
                                                <p className={`${montserratNormal.className} text-base text-gray-cool-700`}>{t(val.description)}</p>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='w-full relative h-full lg:w-[40%] p-[10px] flex'>
                            <div className='w-full relative flex flex-col px-6 md:px-[48px] justify-center items-center rounded-lg border border-gray-cool-300 bg-gray-cool-50 h-full'>
                                <p className={`${montserratNormal.className} text-[20px] text-gray-cool-500 pt-8`}>{t(pricingContent.detail.pricing.title)}</p>
                                <h1 className={`${montserratBold.className} whitespace-nowrap leading-[44px] text-[36px]`}>{t(pricingContent.detail.pricing.price)}</h1>
                                <p className={`${montserratNormal.className} text-[20px] text-gray-cool-500`}>{t(pricingContent.detail.pricing.type_price)}</p>
                                <div className='lg:w-full md:w-[224px] py-8'>
                                    <Button onClick={() => moveToSection("contact")} className={`flex hover:bg-blue-primary-600 bg-blue-primary-500 w-full text-base-white ${montserratMedium.className}`}>
                                        {t(pricingContent.detail.pricing.button_text)}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingSection