import React from 'react'
import chartWrapper from "@/assets/ctasection/chartwrapper.svg"
import Image from 'next/image'

export default function ChartWrapper() {
  return (
    <div className='w-[720px]'>
        <Image src={chartWrapper} />
    </div>
  )
}
