export const servicesContent = {
    title: "service_title",
    description: "service_description"
}

export const servicesTabs = [
    {
        type: "bookeeping",
        url: "/bookkeeping",
        name: "service_bookeeping",
        title: "service_bookeeping_title",
        description: "service_bookeeping_description",
        list_detail: [
            {
                type: "service-bookeeping",
                name: "Jasa Pembukuan",
                lang: "service_bookeeping_list_detail_one"
            },
            {
                type: "service-consultation",
                name: "Jasa Konsultasi",
                lang:"service_bookeeping_list_detail_two"
            },
            {
                type: "service-report",
                name: "Jasa Pembuatan Laporan Keuangan Bulanan",
                lang: "service_bookeeping_list_detail_three"
            },
            {
                type: "reconciliation",
                name: "Rekonsiliasi Bank dan Hutang Piutang",
                lang: "service_bookeeping_list_detail_four",
                hide_en:true
            },
        ]
    },
    {
        type: "tax",
        name: "service_tax",
        url: "/tax",
        title: "service_tax_title",
        description: "service_tax_description",
        list_detail: [
            {
                type: "administration-tax",
                name: "Jasa Administrasi Perpajakan",
                lang: "service_tax_list_detail_one"
            },
            {
                type: "service-report-spt-self",
                name: "Jasa Perhitungan & Pelaporan SPT Tahunan PPh Orang Pribadi",
                lang: "service_tax_list_detail_two"
            },
            {
                type: "service-report-spt-company",
                name: "Jasa Perhitungan & Pelaporan SPT Tahunan PPh Badan",
                lang: "service_tax_list_detail_three"
            },
            {
                type: "service-counting-report-spt",
                name: "Jasa Perhitungan & Pelaporan SPT PPN",
                lang: "service_tax_list_detail_four",
                hide_en:true
            },
        ]
    },
    {
        type: "reconciliatio",
        name: "service_recon",
        url: "/reconciliation-automation",
        title: "service_recon_title",
        description: "service_recon_description",
        list_detail: []
    },
    {
        type: "operation-finance",
        name: "service_finance",
        url: "/finance-operation",
        title: "service_finance_title",
        description: "service_finance_description",
        list_detail: [
            {
                type: "credit-management",
                name: "Manajemen Piutang",
                lang: "service_finance_list_detail_one"
            },
            {
                type: "debit-management",
                name: "Manajemen Hutang",
                lang: "service_finance_list_detail_two"
            },
            {
                type: "salary-management",
                name: "Manajemen Penggajian",
                lang:"service_finance_list_detail_three"
            },
            {
                type: "tax-fraktur",
                name: "Pembuatan Faktur Pajak",
                lang: "service_finance_list_detail_four"
            },
        ]
    },
    {
        type: "legalitation",
        name: "service_legal",
        url: "/legal",
        title: "service_legal_title",
        description: "service_legal_description",
        list_detail: [
            {
                type: "service-legalitation-company",
                name: "Jasa Pengurusan Pendirian Perusahaan (Perorangan)",
                lang: "service_legal_list_detail_one"
            },
            {
                type: "service-legalitation-organization",
                name: "Jasa Pengurusan Pendirian Perusahaan PT/CV/Yayasan",
                lang: "service_legal_list_detail_two"
            },
            {
                type: "service-change-akta",
                name: "Jasa Perubahan Akta Perusahaan",
                lang: "service_legal_list_detail_three"
            },
            {
                type: "service-nib",
                name: "Jasa Penerbitan KBLI dan NIB Baru",
                lang: "service_legal_list_detail_four"
            },
            {
                type: "service-nib",
                name: "",
                lang: "service_legal_list_detail_five"
            },
        ]
    },
]