export const pricingSection = {
    title: "pricing_title",
    main_title: "pricing_main_title",
    description: "pricing_description",
    list: [
        {
            image: "cash",
            title: "pricing_list_one_title",
            description: "pricing_list_one_description"
        },
        {
            image: "bolt",
            title: "pricing_list_two_title",
            description: "pricing_list_two_description"
        },
        {
            image: "clock",
            title: "pricing_list_three_title",
            description: "pricing_list_three_description"
        },
    ],
    detail: {
        title: "pricing_detail_title",
        description: "pricing_detail_description",
        list_title: "pricing_detail_list_title",
        pricing: {
            title: "pricing_start_from",
            price: "pricing_price",
            type_price: "pricing_type_price",
            button_text: "pricing_button_text"
        },
        list: [
            {
                description : "pricing_list_one",
            },
            {
                description : "pricing_list_two",
            },
            {
                description : "pricing_list_three",
            },
            {
                description : "pricing_list_four",
            },
        ]
    }
}