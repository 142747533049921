import { listColorBadges } from '@/libs/color_badges'
import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function Badges({children, className}) {
  return (
    <div className={`${className ? className : "bg-blue-primary-100 text-blue-primary-600"} px-2 py-[2px] rounded-[2px] bg-primary-blue flex justify-center items-center`}>
        {children}
    </div>
  )
}
